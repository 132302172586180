import React, { Component } from 'react';
import './scss/App.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
// import Carousel from 'react-bootstrap/Carousel';
// import ButtonToolBar from 'react-bootstrap/ButtonToolbar';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
import NavBar from './components/NavBar';
import Logo from './assets/Logos/CC-logo-white-text.png';
import HeaderBackgroundImage from './assets/images/Online-Scheduling-backgroud-LightBlue-w2560.jpg'; 

// import fetcher from './utils/fetcher';
// import jsonSearch from './utils/jsonSearch';

var $colors = {
  "navy": "#192359",
  "lightGrey": "#F2F2F2",
  "primary":  "#283479",
  "secondary": "#008ECE"
}

class Scheduler extends Component{
  constructor(props){
    super(props);
    this.state = {
      jsn: [],
      deptSelection: '',
      patientType: '',
      currentStep: 1,
      availableVisitTypes: [],
      availableProviders: [],
      id: `94788,89909,90962,102348,69877,91798,100961,`,
      dept: `3001043,3001108,3001114,3001005,3001181,3001037,`,
      vt: `2759`,
      iframeScroll: window.innerWidth > 814 ? 'no' : 'yes',
      iframeOverflow: 'none',
      testForm: false
    }
    this.mounted = false;
    this.iframeResize = this.iframeResize.bind(this);
  }

  iframeResize(){
    if(window.innerWidth <= 816 && this.state.iframeScroll === 'no'){
      // console.log('less');
      if(this.mounted){
        this.setState({iframeScroll: 'yes'});
      }
    }
    else if(window.innerWidth > 816 && this.state.iframeScroll === 'yes'){
      // if(this.state.iframeScroll == 'yes'){
        // console.log('greater');
        if(this.mounted){
          this.setState({iframeScroll: 'no'});
        }
      // }
    }
  }

  messageHandler(event){
    if(event.data.method === "setHeight" && event.data.height !== "")
    {
      // console.log('NEW HEIGHT: ' + event.data.height);
      document.getElementById('MyChartIframe0').style.height = event.data.height;
    }
    // console.log('Message: ' + JSON.stringify(event.data));
  }

  _setInitialState(tmp){
    this.setState({jsn: tmp});
  }

  componentWillUnmount(){
    this.mounted = false;
    window.removeEventListener('resize', this.iframeResize);
  }
  componentDidMount(){
    this.mounted = true;
    window.addEventListener('resize', this.iframeResize);
    window.addEventListener('message', this.messageHandler);
    try{
      // fetch('https://chrc-app2020.accuraty.ws/api/2sxc/app/chrcv01/api/public/tips', {mode: 'no-cors'})
      // fetch('https://jsonplaceholder.typicode.com/todos/1') // <= works
      // fetch('data/temp.json') 
      //Ajax for IE support:
      var xhttp = new XMLHttpRequest();
      // var tmpJsonHolder = (tmp) => {this.setState({jsn: tmp})};
      var tmpJsonHolder = (tmp) => {
        let qsParams = new URLSearchParams(document.location.search);
        if(qsParams.get("newbuttontest") === "form")
        {
          this.setState({
            jsn: tmp,
            testForm: true
          });
        }
        else
        {
          this.setState({jsn: tmp});
        }
      };
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          // console.log("test");
          // if(this.mounted){
            // console.log(JSON.parse(this.responseText));

            // tmpJsonHolder(JSON.parse(this.responseText).concat([{
            //   DeptName:"Physical Therapy",
            //   osDeptVisitTypes:[
            //   {VTName:"MYCHRISTIE PT EVAL [3600]", VTID:"3600"},
            //   {VTName:"MYCHRISTIE PT PEDIATRIC EVAL [3601]", VTID:"3601"},
            //   {VTName:"MYCHRISTIE PT OP PAIN/DIS EVAL [3602]",VTID:"3602"},
            //   {VTName:"MYCHRISTIE PT PELVIC FLR EVAL [3603]",VTID:"3603"},
            //   {VTName:"MYCHRISTIE PT PARKINSON EVAL [3604]",VTID:"3604"},
            //   {VTName:"MYCHRISTIE PT POST-CON EVAL [3605]",VTID:"3605"},
            //   {VTName:"MYCHRISTIE PT VESTIBULAR EVAL [3606]",VTID:"3606"},
            //   {VTName:"MYCHRISTIE OT HAN/WRI/ELB EVAL [3607]",VTID:"3607"},
            //   {VTName:"MYCHRISTIE PT TMJ EVAL [3608]",VTID:"3608"},
            //   {VTName:"MYCHRISTIE PT LYMPHEDEMA EVAL [3784]",VTID:"3784"}
            //   ],
            //   osid:"500955,500013,500020,500024,500016,500022,500015,501019,500026,500766,500018,500023,500653,500019,500554",
            //   dept:"3001038,3001062,3001293,3001021,3001199",
            //   deptType:"specialty",
            //   Providers:[
            //   {ProviderID:"500955","osProviderVisitTypes":[{VTID:"3600"},{VTID:"3601"}]},
            //   {ProviderID:"500013","osProviderVisitTypes":[{VTID:"3608"},{VTID:"3602"}]},
            //   {ProviderID:"500020","osProviderVisitTypes":[{VTID:"3602"},{VTID:"3605"},{VTID:"3606"},{VTID:"3608"}]},
            //   {ProviderID:"500024","osProviderVisitTypes":[{VTID:"3602"}]},
            //   {ProviderID:"500016","osProviderVisitTypes":[{VTID:"3602"}]},
            //   {ProviderID:"500022","osProviderVisitTypes":[{VTID:"3602"},{VTID:"3605"},{VTID:"3606"}]},
            //   {ProviderID:"500015","osProviderVisitTypes":[{VTID:"3603"}]},
            //   {ProviderID:"501019","osProviderVisitTypes":[{VTID:"3603"},{VTID:"3784"}]},
            //   {ProviderID:"500026","osProviderVisitTypes":[{VTID:"3607"}]},
            //   {ProviderID:"500766","osProviderVisitTypes":[{VTID:"3602"},{VTID:"3604"}]},
            //   {ProviderID:"500018","osProviderVisitTypes":[{VTID:"3602"},{VTID:"3604"}]},
            //   {ProviderID:"500023","osProviderVisitTypes":[{VTID:"3602"},{VTID:"3604"}]},
            //   {ProviderID:"500653","osProviderVisitTypes":[{VTID:"3602"}]},
            //   {ProviderID:"500019","osProviderVisitTypes":[{VTID:"3602"}]},
            //   {ProviderID:"500554","osProviderVisitTypes":[{VTID:"3602"}]}
            //   ]
            //   }]));

            tmpJsonHolder(JSON.parse(this.responseText).concat([{
              DeptName:"Physical Therapy",
              osDeptVisitTypes:[{VTName:"Therapy Services Evaluation", VTID:"3600,3601,3602,3603,3604,3605,3606,3607,3608,3784"}],
              osid:"500955,500013,500020,500024,500016,500022,500015,501019,500026,500766,500018,500023,500653,500019,500554",
              dept:"3001038,3001062,3001293,3001021,3001199",
              deptType:"specialty",
              Providers:[
              {ProviderID:"500955","osProviderVisitTypes":[{VTID:"3600"},{VTID:"3601"}]},
              {ProviderID:"500013","osProviderVisitTypes":[{VTID:"3608"},{VTID:"3602"}]},
              {ProviderID:"500020","osProviderVisitTypes":[{VTID:"3602"},{VTID:"3605"},{VTID:"3606"},{VTID:"3608"}]},
              {ProviderID:"500024","osProviderVisitTypes":[{VTID:"3602"}]},
              {ProviderID:"500016","osProviderVisitTypes":[{VTID:"3602"}]},
              {ProviderID:"500022","osProviderVisitTypes":[{VTID:"3602"},{VTID:"3605"},{VTID:"3606"}]},
              {ProviderID:"500015","osProviderVisitTypes":[{VTID:"3603"}]},
              {ProviderID:"501019","osProviderVisitTypes":[{VTID:"3603"},{VTID:"3784"}]},
              {ProviderID:"500026","osProviderVisitTypes":[{VTID:"3607"}]},
              {ProviderID:"500766","osProviderVisitTypes":[{VTID:"3602"},{VTID:"3604"}]},
              {ProviderID:"500018","osProviderVisitTypes":[{VTID:"3602"},{VTID:"3604"}]},
              {ProviderID:"500023","osProviderVisitTypes":[{VTID:"3602"},{VTID:"3604"}]},
              {ProviderID:"500653","osProviderVisitTypes":[{VTID:"3602"}]},
              {ProviderID:"500019","osProviderVisitTypes":[{VTID:"3602"}]},
              {ProviderID:"500554","osProviderVisitTypes":[{VTID:"3602"}]}
              ]
              }]));
            // this.setState({jsn: this.responseText});
          // }
        }
      };
      xhttp.open('GET', 'https://chrc-app2020.accuraty.ws/api/2sxc/app/chrcv01/api/schedule/main2', true);
      xhttp.send()

      // fetch('https://chrc-app2020.accuraty.ws/api/2sxc/app/chrcv01/api/schedule/main')
      // .then( (resp) => {
      //   // console.log(resp);
      //   return(resp.json());
      // })
      // .then( (jsn) => {
      //   // console.log(JSON.stringify(jsn));
      //   if(this.mounted){
      //     this.setState({jsn: jsn});
      //   }
      // });
      // console.log('END');
    }
    catch(err){
      console.log('error:' + err);
    }
  }

  deptButtons(){
    let deptButtons = [];
    let deptButtonsPrimary = [];
    // let idx = 0;
    // let rowIdx = 1;
    // deptButtonsPrimary.push(
    //   <Col sm={true} className='p-sm-3 px-2 pt-2 pb-1' key={'deptButton-col-ConvenientCare'}>
    //       <Button style={{width: '100%', height: '100%', borderRadius: '10px'}} apidata={`Department: 300253,300250,300254,300255,`} className="py-2 py-sm-4" variant='primary' key={'deptButton-ConvenientCare' } 
    //         onClick={() => { 
    //           if(this.mounted){
    //             window.dataLayer.push({
    //               'event' : `Step${this.state.currentStep}`,
    //               'department' : `ConvenientCare`
    //             });
    //             this.setState(
    //               {
    //                 currentStep: 3, 
    //                 deptSelection: 'Convenient Care', 
    //                 id: '300253,300250,300254,300255',
    //                 dept: '3001016,3001117,3001045,3001103', 
    //                 patientType: 'Convenient Care',
    //                 vt: '2760'
    //               }
    //             )
    //           }
    //         }}
    //         data-gtm-tag-id={`Step${this.state.currentStep}-ConvenientCare`}
    //       >CONVENIENT CARE
    //       </Button>
    //     </Col>
    // );
    for(let x of this.state.jsn){
      let osDeptId = x.dept;
      // for(let y of x.osDeptLocations){
      //   if(osDeptId === ''){
      //     osDeptId = y.osID;
      //   }
      //   else{
      //     osDeptId = osDeptId + ',' + y.osID;
      //   }
      // }
      //without the trailing comma the address won't show on the appoinment cards
      // trailing comma throws error in new system osDeptId = osDeptId + ',';
      if(x.deptType == 'primary'){
        if(x.DeptName == 'Convenient Care')
        {
          deptButtonsPrimary.unshift(
            <Col sm={true} className='p-sm-3 px-2 pt-2 pb-1' key={'deptButton-col-ConvenientCare'}>
              <Button style={{wordBreak: 'break-word', width: '100%', height: '100%', borderRadius: '10px'}} apidata={`Department: ${osDeptId}`} className="py-2 py-sm-4" variant='primary' key={'deptButton-ConvenientCare' } 
                onClick={() => {
                  if(this.mounted){
                    window.dataLayer.push({
                      'event' : `Step${this.state.currentStep}`,
                      'department' : `ConvenientCare`
                    });
                    this.setState(
                      {
                        currentStep: 3, 
                        deptSelection: x.DeptName, 
                        id: x.osid,
                        dept: osDeptId, 
                        patientType: x.DeptName,
                        vt: x.osDeptVisitTypes[0].VTID
                      }
                    )
                  }
                }}
                data-gtm-tag-id={`Step${this.state.currentStep}-${x.DeptName.replace(/[^a-zA-Z0-9-_]/g, '')}`}
              >{x.DeptName}
              </Button>
            </Col>
          );
        }
        else
        {
          deptButtonsPrimary.push(
            <Col sm={true} className='p-sm-3 px-2 pt-2 pb-1' key={'deptButton-col-' + x.DeptName}>
              <Button style={{wordBreak: 'break-word', width: '100%', height: '100%', borderRadius: '10px'}} apidata={`Department: ${osDeptId}`} className="py-2 py-sm-4" variant='primary' key={'deptButton-' + x.DeptName} 
                onClick={() => {
                  if(this.mounted){
                    window.dataLayer.push({
                      'event' : `Step${this.state.currentStep}`,
                      'department' : `${x.DeptName.replace(/[^a-zA-Z0-9-_]/g, '')}`
                    });
                    this.setState(
                    {
                      currentStep: 2, 
                      deptSelection: x.DeptName, 
                      availableVisitTypes: x.osDeptVisitTypes,
                      dept: osDeptId,
                      availableProviders: x.Providers
                    }
                )}}}
                data-gtm-tag-id={`Step${this.state.currentStep}-${x.DeptName.replace(/[^a-zA-Z0-9-_]/g, '')}`}
              >{x.DeptName}
              </Button>
            </Col>
          );
        }
        // deptButtonsPrimary.push(
        //   <Col sm={true} className='p-sm-3 px-2 pt-2 pb-1' key={'deptButton-col-' + x.DeptName}>
        //     <Button style={{wordBreak: 'break-word', width: '100%', height: '100%', borderRadius: '10px'}} apidata={`Department: ${osDeptId}`} className="py-2 py-sm-4" variant='primary' key={'deptButton-' + x.DeptName} 
        //       onClick={() => {
        //         if(this.mounted){
        //           window.dataLayer.push({
        //             'event' : `Step${this.state.currentStep}`,
        //             'department' : `${x.DeptName.replace(/[^a-zA-Z0-9-_]/g, '')}`
        //           });
        //           this.setState(
        //           {
        //             currentStep: 2, 
        //             deptSelection: x.DeptName, 
        //             availableVisitTypes: x.osDeptVisitTypes,
        //             dept: osDeptId,
        //             availableProviders: x.Providers
        //           }
        //       )}}}
        //       data-gtm-tag-id={`Step${this.state.currentStep}-${x.DeptName.replace(/[^a-zA-Z0-9-_]/g, '')}`}
        //     >{x.DeptName}
        //     </Button>
        //   </Col>
        // );
      }
      else{
        deptButtons.push(
          <Col sm={true} className='p-sm-3 px-2 pt-2 pb-1' key={'deptButton-col-' + x.DeptName}>
            <Button style={{wordBreak: 'break-word', width: '100%', height: '100%', borderRadius: '10px'}} apidata={`Department: ${osDeptId}`} className="py-2 py-sm-4" variant='primary' key={'deptButton-' + x.DeptName} 
              onClick={() => {
                if(this.mounted){
                  window.dataLayer.push({
                    'event' : `Step${this.state.currentStep}`,
                    'department' : `${x.DeptName.replace(/[^a-zA-Z0-9-_]/g, '')}`
                  });
                  this.setState(
                  {
                    currentStep: 2, 
                    deptSelection: x.DeptName, 
                    availableVisitTypes: x.osDeptVisitTypes,
                    dept: osDeptId,
                    availableProviders: x.Providers
                  }
              )}}}
              data-gtm-tag-id={`Step${this.state.currentStep}-${x.DeptName.replace(/[^a-zA-Z0-9-_]/g, '')}`}
            >{x.DeptName}
            </Button>
          </Col>
        );
      }
      
      // idx++;
      // if(idx === 2){
      //   idx = 0;
      //   deptButtons.push(
      //     <Col sm={12} key={'deptButton-placeholder-col-' + rowIdx++}/>
      //   );
      // }
    }
    return(
      <>
      <Row className='p-3' style={{flex: '1 1 auto', backgroundColor: $colors.lightGrey}}>
        <Container key='deptPrimary-buttons-container' className='dept-buttons-container p-0' fluid>
          <Row className='px-0 dept-buttons-row' key='dept-buttons-row' sm={2}>
            <Col sm={12} className='p-sm-3 px-2 pt-2 pb-1'>
              <span className='ml-2 text-secondary h3'><strong>Primary Care</strong></span>
            </Col>
            {deptButtonsPrimary}
          </Row>
        </Container>
      </Row>
      <Row className='p-3 mt-5' style={{flex: '1 1 auto', backgroundColor: $colors.lightGrey}}>
        <Container key='dept-buttons-container' className='dept-buttons-container p-0' fluid>
          <Row className='px-0 dept-buttons-row' key='dept-buttons-row' sm={2}>
            <Col sm={12} className='p-sm-3 px-2 pt-2 pb-1'>
              <span className='ml-2 text-secondary h3'><strong>Specialties</strong></span>
            </Col>
            {deptButtons}
          </Row>
        </Container>
      </Row>
      </>
    );
  }

  DEMOrender(){
    return(
      <Container fluid>
        <Row>
          <Col md={4}>
            <p>{JSON.stringify(this.state.jsn)}</p>
          </Col>
          <Col md={4}>
            <p>Test2</p>
          </Col>
        </Row>
      </Container>
    );
  }

  render(){
    if(this.state.currentStep === 1){
      // console.log(this.state.availableVisitTypes);
      if(this.state.testForm)
      {
        return(
          <Container fluid style={{ backgroundColor: 'white', height: '100%', width: '100%', display: 'flex', flexFlow: 'column'}} className='py-sm-5 px-4'>
            
              <Row className='pb-sm-5 px-3'>
                <Col className='mb-0 py-3 py-sm-0'>
                  <span className='text-secondary h2'><strong>STEP TEST: </strong></span><span className='ml-2 text-primary h3'><strong>Fill Out the Form to test if the department is valid.</strong></span>
                </Col>
              </Row>
            
              <Row >
                <Col >
                  <Form>
                    <Form.Text>Please fill in the fields with comma separated lists. The placeholder of the fields will show an example</Form.Text>
                    <Form.Group controlId='form-URL'>
                      <Form.Label>URL</Form.Label>
                      {/* <Form.Control defaultValue="https://epicmycharttest.carle.com/Christie/" type="text"/> */}
                      <Form.Control defaultValue="https://mychart-np.et0316.epichosted.com/MyChartPJX" type="text"/>
                    </Form.Group>
                    <Form.Group controlId='form-API'>
                      <Form.Label>API Key</Form.Label>
                      <Form.Control defaultValue="cONEOtyO6245302" type="text"/>
                    </Form.Group>
                    <Form.Group controlId='form-dept'>
                      <Form.Label>Dept [Location IDs]</Form.Label>
                      <Form.Control defaultValue="3001047,3001076,3001088" type="text"/>
                    </Form.Group>
                    <Form.Group controlId='form-vt'>
                      <Form.Label>VT</Form.Label>
                      <Form.Control defaultValue="2758,2759" type="text"/>
                    </Form.Group>
                    <Form.Group controlId='form-provider'>
                      <Form.Label>Providers [OPTIONAL]</Form.Label>
                      <Form.Control placeholder="2758,2759" type="text"/>
                    </Form.Group>
                    <Button 
                      onClick={() => {
                        if(window.hasOwnProperty('testScript'))
                        {
                          document.body.removeChild(window.testScript);
                        }
                        let testURL = document.getElementById('form-URL').value.replace(/\s/g, '');
                        //add script link with url
                        let scriptEle = document.createElement("script");
                        scriptEle.setAttribute("src", testURL + '/Scripts/lib/Widget/widget_sdk.js');
                        scriptEle.setAttribute("type", "text/javascript");
                        // success event 
                        scriptEle.addEventListener("load", () => {
                          console.log("File loaded");
                          let testAPI = document.getElementById('form-API').value.replace(/\s/g, '');
                          let testURL = document.getElementById('form-URL').value.replace(/\s/g, '');
                          let element = document.getElementById("MyChart");
                          while (element.firstChild) {
                            element.removeChild(element.firstChild);
                          }
                          
                          let additionalParams = {
                            "dept": document.getElementById('form-dept').value.replace(/\s/g, ''),
                            "vt": document.getElementById('form-vt').value.replace(/\s/g, '')
                          };
                          let tmp = document.getElementById('form-provider').value.replace(/\s/g, '').trim();
                          if(tmp !== null && tmp.length !== 0)
                          {
                            additionalParams.id = tmp;
                          }
                          /*global mychartWidget*/
                          mychartWidget.renderWidget({
                            "url": testURL,
                            "apiKey":testAPI,
                            "widgetType": "openscheduling",
                            "containerId":"MyChart",
                            "additionalParams": additionalParams
                          })
                        });
                        // error event
                        scriptEle.addEventListener("error", (ev) => {
                          console.log("Error on loading file", ev);
                        });
                        document.body.appendChild(scriptEle);
                        window.testScript = scriptEle;
                      }}
                    >TEST</Button>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div id="MyChart"></div>
                </Col>
              </Row>
            
          </Container>
        );
      }
      else
      {
        return(
          <Container fluid style={{ backgroundColor: 'white', height: '100%', width: '100%', display: 'flex', flexFlow: 'column'}} className='py-sm-5 px-4'>
            
              <Row className='pb-sm-5 px-3'>
                <Col className='mb-0 py-3 py-sm-0'>
                  <span className='text-secondary h2'><strong>STEP 1: </strong></span><span className='ml-2 text-primary h3'><strong>Choose a department.</strong></span>
                </Col>
              </Row>
            
              {/* <Row className='p-3' style={{flex: '1 1 auto', backgroundColor: $colors.lightGrey}}> */}
                {this.deptButtons()} 
              {/* </Row> */}
            
          </Container>
        );
      }
    }
    else if(this.state.currentStep === 2){
      // let availableVisitTypes = this.state.availableVisitTypes;
      // console.log(this.state.availableVisitTypes);
      var widgetTestButton = <></>;
      if(this.state.deptSelection == "Ophthalmology")
      {
        widgetTestButton = <Col className='p-sm-3 px-2 pt-2 pb-1' sm={true}>
            <Button 
              style={{width: '100%', height: '100%', borderRadius: '10px'}} 
              className="py-2 py-sm-4" 
              variant='primary' 
              key='patientButton-new' 
              onClick={() => {
                if(this.mounted)
                {
                  this.setState({currentStep: 3, patientType: 'Established-Crouch', vt: '2770'})
                }
              }}
            >
            <Container>
                <Row xs={1}>
                  {/* <Col xs={true} style={{fontWeight: '500'}}>{this.state.deptSelection}</Col> */}
                  <Col xs={true}>ESTABLISHED-CROUCH</Col>
                </Row>
              </Container>
            </Button>
          </Col>
        ;
      }
      if(this.state.deptSelection == "Orthopedics and Sports Medicine")
      {
        widgetTestButton = <Col className='p-sm-3 px-2 pt-2 pb-1' sm={true}>
            <Button 
              style={{width: '100%', height: '100%', borderRadius: '10px'}} 
              className="py-2 py-sm-4" 
              variant='primary' 
              key='patientButton-new' 
              onClick={() => {
                if(this.mounted)
                {
                  this.setState({currentStep: 3, patientType: 'Established-Cusick', vt: '3674'})
                }
              }}
            >
            <Container>
                <Row xs={1}>
                  {/* <Col xs={true} style={{fontWeight: '500'}}>{this.state.deptSelection}</Col> */}
                  <Col xs={true}>ESTABLISHED-CUSICK</Col>
                </Row>
              </Container>
            </Button>
          </Col>
        ;
      }
      if(this.state.availableVisitTypes.length === 0){
        return(
          <Container fluid style={{backgroundColor: 'white', height: '100%', width: '100%',  display: 'flex', flexFlow: 'column'}} className='py-sm-5 px-4'>
          
            
              <Row className='pb-sm-5 py-3 py-sm-0 px-3'>
                <Col className='mb-0 '>
                  <span className='text-secondary h2'><strong>STEP 2: </strong></span><span className='ml-2 text-primary h3'><strong>Are you a new or established <span style={{color: $colors.secondary}}>{this.state.deptSelection}</span> patient?</strong></span>
                </Col>
              </Row>
            
              <Row className='p-3' style={{backgroundColor: $colors.lightGrey}}>
                <Container key='dept-buttons-container' className='dept-buttons-container p-0' fluid>
                  <Row className='px-0 dept-buttons-row' key='dept-buttons-row' sm={2}>
                    <Col className='p-sm-3 px-2 pt-2 pb-1' sm={true}>
                      <Button 
                        style={{width: '100%', height: '100%', borderRadius: '10px'}} 
                        className="py-2 py-sm-4" 
                        variant='primary' 
                        key='patientButton-new' 
                        onClick={() => {
                          if(this.mounted)
                          {
                            window.dataLayer.push({
                              'event' : `Step${this.state.currentStep}`,
                              'department' : `${this.state.deptSelection.replace(/[^a-zA-Z0-9-_]/g, '')}`,
                              'visitType' : `NewPatient`
                            });
                            this.setState({currentStep: 3, patientType: 'NEW PATIENT', vt: '2758'})
                          }
                        }}
                        data-gtm-tag-id={`Step${this.state.currentStep}-${this.state.deptSelection.replace(/[^a-zA-Z0-9-_]/g, '')}-NewPatient`}
                      >
                      <Container>
                          <Row xs={1}>
                            {/* <Col xs={true} style={{fontWeight: '500'}}>{this.state.deptSelection}</Col> */}
                            <Col xs={true}>NEW PATIENT</Col>
                          </Row>
                        </Container>
                      </Button>
                    </Col>
                    <Col className='p-sm-3 px-2 pt-2 pb-1' sm={true}>
                      <Button 
                        style={{width: '100%', height: '100%', borderRadius: '10px'}} 
                        className="py-2 py-sm-4" 
                        variant='primary' 
                        key='patientButton-established' 
                        onClick={() => {
                          if(this.mounted)
                          {
                            window.dataLayer.push({
                              'event' : `Step${this.state.currentStep}`,
                              'department' : `${this.state.deptSelection.replace(/[^a-zA-Z0-9-_]/g, '')}`,
                              'visitType' : `EstablishedPatient`
                            });
                            this.setState({currentStep: 3, patientType: 'ESTABLISHED PATIENT', vt: '2759'})
                          }
                        }}
                        data-gtm-tag-id={`Step${this.state.currentStep}-${this.state.deptSelection.replace(/[^a-zA-Z0-9-_]/g, '')}-EstablishedPatient`}
                      >
                        <Container>
                          <Row xs={1}>
                            {/* <Col xs={true} style={{fontWeight: '500'}}>{this.state.deptSelection}</Col> */}
                            <Col xs={true}>ESTABLISHED PATIENT</Col>
                          </Row>
                        </Container>
                      </Button>
                    </Col>
                    <Col className='p-sm-3 px-2 pt-2 pb-1' sm={12}>
                      <Button style={{width: '100%', height: '100%', borderRadius: '10px'}} className="py-2 py-sm-4" variant='secondary' key='patientButton-startOver' onClick={() => {if(this.mounted){this.setState({currentStep: 1, patientType: '', deptSelection: ''})}}}>START OVER</Button>
                    </Col>
                  </Row>
                </Container>
              </Row>
            
          </Container>
            
        );
      }
      else{
        //additional visit types found.
        let additionalVisitButtons = [];
        this.state.availableVisitTypes.map( (visit) => {
          additionalVisitButtons.push(
            <Col className='p-sm-3 px-2 pt-2 pb-1' key={'patientButtonCol-' + visit.VTName} sm={true}>
              <Button 
                style={{width: '100%', height: '100%', borderRadius: '10px'}} 
                apidata={`Visit: ${visit.VTID}`} 
                className="py-2 py-sm-4" 
                variant='primary' 
                key={'patientButton-' + visit.VTName} 
                onClick={() => {
                  if(this.mounted)
                  {
                    window.dataLayer.push({
                      'event' : `Step${this.state.currentStep}`,
                      'department' : `${this.state.deptSelection.replace(/[^a-zA-Z0-9-_]/g, '')}`,
                      'visitType' : `${visit.VTName.replace(/[^a-zA-Z0-9-_]/g, '')}`
                    });
                    this.setState({currentStep: 3, patientType: visit.VTName, vt: visit.VTID})
                  }
                }}
                data-gtm-tag-id={`Step${this.state.currentStep}-${this.state.deptSelection.replace(/[^a-zA-Z0-9-_]/g, '')}-${visit.VTName.replace(/[^a-zA-Z0-9-_]/g, '')}`}
              >
                <Container>
                  <Row xs={1}>
                    {/* <Col xs={true} style={{fontWeight: '500'}}>{this.state.deptSelection}</Col> */}
                    <Col xs={true}>{visit.VTName}</Col>
                  </Row>
                </Container>
              </Button>
            </Col>
          );
          return(<></>);
        });
        return(
          <Container fluid style={{backgroundColor: 'white', height: '100%', width: '100%',  display: 'flex', flexFlow: 'column'}} className='py-sm-5 px-4'>
          
            
              <Row className='pb-sm-5 py-3 py-sm-0 px-3' style={{alignItems: 'center'}}>
                <Col className='mb-0' sm={7}>
                  <span className='text-secondary h2'><strong>STEP 2: </strong></span><span className='ml-2 text-primary h3'><strong>What type of patient are you?</strong></span>
                </Col>
                <Col className='mb-3 mb-sm-0 ' sm={5}>
                  <h2 className=' mb-0 text-secondary breadCrumbs' ><strong><span className='' onClick={() => {this.setState({currentStep: 1, patientType: '', deptSelection: ''})}}>{this.state.deptSelection}</span></strong></h2>
                </Col>
              </Row>
            
              <Row className='p-3' style={{backgroundColor: $colors.lightGrey}}>
                <Container key='dept-buttons-container' className='dept-buttons-container p-0' fluid>
                  <Row className='px-0 dept-buttons-row' key='dept-buttons-row' sm={2}>
                    {/* <Col className='p-3' sm={true}>
                      <Button block variant='primary' key='1patientButton-new' onClick={() => {this.setState({currentStep: 3, patientType: 'NEW PATIENT'})}}>NEW PATIENT</Button>
                    </Col>
                    <Col className='p-3' sm={true}>
                      <Button block variant='primary' key='1patientButton-established' onClick={() => {this.setState({currentStep: 3, patientType: 'ESTABLISHED PATIENT'})}}>ESTABLISHED PATIENT</Button>
                    </Col> */}
                    {additionalVisitButtons}
                    {widgetTestButton}
                    <Col className='p-sm-3 px-2 pt-2 pb-1' sm={12}>
                      <Button style={{width: '100%', height: '100%', borderRadius: '10px'}} className="py-2 py-sm-4" variant='secondary' key='1patientButton-startOver' onClick={() => {if(this.mounted){this.setState({currentStep: 1, patientType: '', deptSelection: ''})}}}>START OVER</Button>
                    </Col>
                    {/* <Col>
                      <p>{JSON.stringify(this.state.availableProviders)}</p>
                    </Col> */}
                  </Row>
                </Container>
              </Row>
            
          </Container>
            
        );
      }
    }
    else if(this.state.currentStep === 3){
      let osProviders = "";
      for(let x of this.state.availableProviders){
        for(let y of x.osProviderVisitTypes){
          if(this.state.vt.split(",").includes(y.VTID)){
            if(osProviders === ""){
              osProviders = x.ProviderID;
            }
            else{
              osProviders = osProviders + ',' + x.ProviderID;
            }
          }
        }
      }
        //without the trailing comma the provider picture won't be shown.
        // Trailing comma causes error in new system osProviders = osProviders + ',';
        // if(this.state.vt == 2758){
        //   //New Patient visit type
        //   if(x.osProviderVisitTypes == 2758){
        //     if(osProviders == ""){
        //       osProviders = x.osProviderID;
        //     }
        //     else{
        //       osProviders = osProviders + ',' + x.osProviderID;
        //     }
        //   }
        // }
        // else if(this.state.vt == 2759){
        //   //Exisiting Patient
        //   if(x.osProviderVisitTypes == 2759){
        //     if(osProviders == ""){
        //       osProviders = x.osProviderID;
        //     }
        //     else{
        //       osProviders = osProviders + ',' + x.osProviderID;
        //     }
        //   }
        // }
        // else if(this.state.vt == 2760){
        //   //Convenient Care, every has to accept
        //   if(osProviders == ""){
        //     osProviders = x.osProviderID;
        //   }
        //   else{
        //     osProviders = osProviders + ',' + x.osProviderID;
        //   }
        // }
        // else{
        //   if(osProviders == ""){
        //     osProviders = x.osProviderID;
        //   }
        //   else{
        //     osProviders = osProviders + ',' + x.osProviderID;
        //   }
        // }
      let convenientCareMessage = "";
      if(this.state.vt == "2760")
      {
        osProviders = this.state.id;
        convenientCareMessage = <Row className='px-3 pb-3' style={{alignItems: 'center'}}><Col><span className='text-primary h4'><strong>If you are experiencing chest pain, symptoms of stroke or severe shortness of breath, please call 911 for immediate medical attention.</strong></span></Col></Row>;
      }
      if(this.state.vt == "2770")
      {
        osProviders = "88943";
      }
      if(this.state.vt == "3674")
      {
        osProviders = "100004";
      }
      // This is the QS param to strip the osProviders for testing
      console.log(window.location.search);
      const queryParams = new URLSearchParams(window.location.search)
      const term = queryParams.get("id")
      if(term == 'true')
      {
        osProviders='';
        console.log('id has been stripped for testing.')
      }
      let frame;
      // if(osProviders != null && osProviders != '')
      // {
      //  frame = <script type="text/javascript">
      //   {/* window.addEventListener('load', function(event){ */}
      //   {/* https://epicmycharttest.carle.com/ChristieFUTDEV/openscheduling/embedded?apikey=cJgGMkxC8811788&widgetid=MyChartIframe0&dept=3001011,3001059,3001077,3001100,3001048,3001282&vt=2758&id=86966,92269,86932,86999,94784,103849,98306,86915,99329,97196,100003,89815 */}
      //   {/* https://epicmycharttest.carle.com/ChristieFUTDEV/openscheduling/embedded?apikey=cJgGMkxC8811788&widgetid=MyChartIframe0&dept=3001121,3001124,3001156,3001195,300156,3001099,3001297&vt=2758&id=94151,92534,101845,86912,81161,102473,99953,94522,86649,89657,104486,105050 */}
      //   {/*global mychartWidget*/mychartWidget.renderWidget({
      //     // "url":"https://www.mychristie.com/Christie/", // <- LIVE
      //     "url":"https://epicmycharttest.carle.com/Christie/", // <- STAGING
      //     //"apiKey":"cONEOtyO6245302", // <- LIVE
      //     "apiKey": "xqItRC704804694", // <- STAGING
      //     "widgetType": "openscheduling",
      //     "containerId":"MyChart",
      //     "additionalParams":{
      //     "dept":`${this.state.dept}`,
      //     "vt":`${this.state.vt}`,
      //     "id":`${osProviders}`
      //     }
      //     })}
      //   {/* }); */}
      // </script>;
      // }
      // else
      // {
        frame = <iframe 
        title="Epic" 
        id="MyChartIframe0" 
        src={`https://epicmycharttest.carle.com/Christie/openscheduling/embedded?apikey=xqItRC704804694&widgetid=MyChartIframe0&dept=${this.state.dept}&vt=${this.state.vt}`} 
        onLoad={() => {
          let tmp = document.getElementById('MyChartIframe0'); 
          tmp.style.height = tmp.contentWindow.document.body.scrollHeight+"px";
          console.log("resizing");
        }}
        scrolling="no"
        class="MyChartIframe" 
        style={{width: '100%'}}
        
      ></iframe>;
      // }
      return(
        <>
        <Container fluid style={{backgroundColor: 'white', height: '100%', width: '100%', display: 'flex', flexFlow: 'column'}} className='py-sm-5 px-4'>
            <Row className='pb-sm-5 py-3 py-sm-0 px-3' style={{alignItems: 'center'}}>
              <Col className='mb-3 mb-sm-0 'sm={7} >
                <span className='text-secondary h2'><strong>STEP 3: </strong></span><span className='ml-2 text-primary h3'><strong>Pick a provider, date, and time for your appointment.</strong></span>
              </Col>
              <Col className='mb-0'sm={5}>
                  <h2 className='mb-0 text-secondary breadCrumbs' ><strong><span onClick={() => {this.setState({currentStep: 1, patientType: '', deptSelection: ''})}}>{this.state.deptSelection}</span></strong></h2>
                  <h3 className='mb-0 text-primary h3 breadCrumbs' ><strong><span onClick={() => {this.setState({currentStep: 2})}}>{this.state.patientType}</span></strong></h3>
              </Col>
            </Row>
            {convenientCareMessage}
            <Row className='p-0' style={{ flex: '1 1 auto'}}>
              <Container key='dept-buttons-container' style={{display: 'flex', flexFlow: 'column'}} className='dept-buttons-container p-0' fluid>
                <Row key='dept-buttons-row' className='dept-buttons-row p-3 mb-sm-3 mb-2' style={{backgroundColor: $colors.lightGrey}}>
                  <Col className='p-sm-3 px-2 pt-2 pb-1' sm={true}>
                    <Button style={{width: '100%', height: '100%', borderRadius: '10px'}} className="py-2 py-sm-4" variant='secondary' key='patientButton-startOver' onClick={() => {if(this.mounted){this.setState({currentStep: 1, patientType: '', deptSelection: ''})}}}>START OVER</Button>
                  </Col>
                </Row>
                {/* <Row className='mt-sm-3 mt-2 px-0' style={{flex: '1 1 auto', justifyContent: 'center'}}>
                  <div style={{overflow: 'hidden', width:'100%', height: '530px', border: 'none', minHeight: '530px', maxWidth: '732px',  backgroundColor: $colors.lightGrey}}> */}
                    {/* Epic recaptcha offscreen fix, setting scrolling to yes to add extra scroll */}
                    {/* <iframe title="openSchedulingFrame" id="openSchedulingFrame" key='openSchedulingFrame' className="widgetframe p-3" scrolling='yes' src={`https://www.mychristie.com/Christie/openscheduling/SignupAndSchedule/EmbeddedSchedule?id=${osProviders}&dept=${this.state.dept}&vt=${this.state.vt}`} style={{width:'100%', height: '100%', border: 'none', minHeight: '530px', maxWidth: '732px', marginLeft: 'auto', marginRight: 'auto', backgroundColor: $colors.lightGrey}}></iframe> */}
                    

                    {/* <div id="EpicMyChartEmbed"></div>
                    {(() => 
                    {
                      try
                      {
                        /*global mychartWidget
                        mychartWidget.renderWidget
                        ({
                          "url":"https://epicmycharttest.carle.com/ChristieFUTDEV",
                          "apiKey":"cJgGMkxC8811788",
                          "widgetType": "openscheduling",
                          "containerId":"EpicMyChartEmbed",
                          "additionalParams":
                          {
                            "dept":`${this.state.dept}`,
                            "vt":`${this.state.vt}`,
                            "id":`${osProviders}`
                          }
                        })
                      }
                      catch(e)
                      {
                        return(<p>Error Accessing MyChart.</p>);
                      }
                    })()} */}



                  {/* </div>
                </Row> */}
              </Container>
            </Row>
          
        </Container>
        {/* <div id="MyChart"> */}
          {/* SCHD3 api = https://epicmycharttest.carle.com/Christie/openscheduling/embedded?apikey=xqItRC704804694&amp;widgetid=MyChartIframe0 */}
          {/* LIVE = https://www.mychristie.com/Christie/openscheduling/embedded?apikey=cONEOtyO6245302&amp;widgetid=MyChartIframe0 */}
        {/* {frame} */}
        {/* </div> */}
        <div id="MyChart"></div>
        {/* SCHD3 api old 20230322JDT = https://epicmycharttest.carle.com/Christie/openscheduling/embedded?apikey=xqItRC704804694&amp;widgetid=MyChartIframe0 */}
        {/* SCHD3 api = https://mychart-np.et0316.epichosted.com/MyChartPJX/openscheduling/embedded?apikey=cONEOtyO6245302&amp;widgetid=MyChartIframe0 */}
        {/* LIVE = https://www.mychristie.com/Christie/openscheduling/embedded?apikey=cONEOtyO6245302&amp;widgetid=MyChartIframe0 */}
        <script type="text/javascript">
          {/* window.addEventListener('load', function(event){ */}
          {/* https://epicmycharttest.carle.com/ChristieFUTDEV/openscheduling/embedded?apikey=cJgGMkxC8811788&widgetid=MyChartIframe0&dept=3001011,3001059,3001077,3001100,3001048,3001282&vt=2758&id=86966,92269,86932,86999,94784,103849,98306,86915,99329,97196,100003,89815 */}
          {/* https://epicmycharttest.carle.com/ChristieFUTDEV/openscheduling/embedded?apikey=cJgGMkxC8811788&widgetid=MyChartIframe0&dept=3001121,3001124,3001156,3001195,300156,3001099,3001297&vt=2758&id=94151,92534,101845,86912,81161,102473,99953,94522,86649,89657,104486,105050 */}
          {/*global mychartWidget*/mychartWidget.renderWidget({
            "url":"https://mychart-np.et0316.epichosted.com/MyChartPJX/",
            "apiKey":"cONEOtyO6245302",
            "widgetType": "openscheduling",
            "containerId":"MyChart",
            "additionalParams":{
            "dept":`${this.state.dept}`,
            "vt":`${this.state.vt}`
            }
            })}
          {/* }); */}
        </script>
        </>
      );
    }
    else{
      return(<Button style={{width: '100%', }} variant='secondary' key='patientButton-startOver' onClick={() => {if(this.mounted){this.setState({currentStep: 1, patientType: '', deptSelection: ''})}}}>START OVER</Button>);
    }
  }
}

class App extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render(){
    return(
      <Container className='container-main px-0' style={{height: '100%', display: 'flex', flexFlow: 'column'}} fluid>
        <NavBar />
        <Container className='px-0' id="header-container" fluid>
          <Row className='py-0 m-0' id='header-row' style={{ justifyContent: 'left', paddingLeft: '2.5rem', paddingRight: '2.5rem',  backgroundImage: 'url(' + HeaderBackgroundImage + ')', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} >
            <Col xs={5} sm={true} className='py-0 px-0'  style={{alignSelf: 'center'}}>
              <a 
                href="https://www.christieclinic.com/" 
                onClick={() => {
                  window.dataLayer.push({
                    'event' : `nav`,
                    'btn' : `CC-Main-Logo`
                  });
                  return true;
                }}
                alt="Christie Clinic" 
                target="_blank" 
                rel="noreferrer"
              >
                <Image src={Logo} alt='Christie Clinic Logo' id="ChristieClinicLogo" className='pl-sm-2' style={{maxHeight: '100px'}} fluid />
              </a>
            </Col>
            <Col xs={7} sm={true} className='px-0 py-3'>
              <Container className='py-3 px-0' style={{justifyContent: 'right', width: '100%'}} fluid>
                <Row className='py-0 px-0' style={{justifyContent: 'center'}}>
                  <h1 className="mb-0 mb-sm-2" style={{color: 'white', width: '100%', textAlign: 'right'}}>ONLINE SCHEDULING</h1>
                </Row>
                <Row className='py-0 pr-sm-3 pr-0 pl-0' style={{justifyContent: 'center'}}>
                  <h2 className="mb-0 mb-sm-2" style={{color: 'white', width: '100%', textAlign: 'right'}}>Follow the steps below.</h2>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container> 
        
        <Scheduler /> 
        <Container className=' body' style={{ flex: '1 1 auto', display: 'flex', backgroundColor: 'white', paddingLeft: '4.16%', paddingRight: '4.16%'}} fluid>
          <Row className='px-0' style={{minHeight: '100%', minWidth: '100%'}}>
            
          </Row>
        </Container>
        <Container className='px-0 footer' fluid>
          <Row className='px-3 py-1 m-0 ftr-menu' >
            <Col sm={8}>
              <a 
                href="https://www.christieclinic.com/" 
                onClick={() => {
                  window.dataLayer.push({
                    'event' : `nav`,
                    'btn' : `CC-Main-Footer`
                  });
                  return true;
                }}
                alt="Christie Clinic" 
                target="_blank" 
                rel="noreferrer" 
                style={{display: 'inline-block'}}
              >
                <p className="textSmall m-0" >Return to www.ChristieClinic.com</p>
              </a>
            </Col>
          </Row>
          <Row className='p-3 m-0' >
            <Col sm={8}>
              <p className='textSmall m-0' style={{color: 'white'}}>&copy; 2021 Christie Clinic</p>
            </Col>
            <Col sm={4} id="ftr-privacy-policy">
              <a 
                href="https://www.christieclinic.com/patient-information/patient-privacy-notice/" 
                onClick={() => {
                  window.dataLayer.push({
                    'event' : `nav`,
                    'btn' : `PrivacyPolicy`
                  });
                  return true;
                }}
                alt="Privacy Policy" 
                target="_blank" 
                rel="noreferrer" 
                style={{display: 'inline-block'}}
              >
                <p className='textSmall m-0' style={{color: 'white', textAlign: 'right'}}>Privacy Policy</p>
              </a>
            </Col>
          </Row>
        </Container>
      </Container> 
    );
  }
 
  Schedulerrender(){
    return(
        <Scheduler />
    );
  }
}

export default App;

